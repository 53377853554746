<template>
	<div v-loading="showLoading" class="device-list">
		<div class="opt">
			<el-button type="primary" @click="add">增加设备</el-button>
		</div>
		<el-table :data="data" stripe>
			<el-table-column
				v-for="item in columns"
				:key="item.prop"
				:prop="item.prop"
				:label="item.label"
			></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button
						size="small"
						@click="modify(scope.row)"
					>更新</el-button>
					<el-button
						size="small"
						type="danger"
						@click="removeDevice(scope.row.id)"
					>删除设备</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog
			v-loading="modifyDialogLoading"
			:visible.sync="showDialog"
			:title="operationType === 'add' ? '增加设备' : '修改设备信息'"
			@close="$refs.deviceForm.clearValidate()"
		>
			<el-form
				ref="deviceForm"
				:model="modifyData"
				:rules="rules"
				label-width="100px"
			>
				<el-form-item label="设备SN号" prop="sn">
					<el-input v-model.trim="modifyData.sn"></el-input>
				</el-form-item>
				<el-form-item label="所属公司" prop="company">
					<el-select v-model="modifyData.company" placeholder="请选择所属公司">
						<el-option
							v-for="item in companyList"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model.trim="modifyData.name"></el-input>
				</el-form-item>
				<el-form-item label="设备状态" prop="status">
					<el-select v-model="modifyData.status" placeholder="请选择状态">
						<el-option label="故障" :value="0"></el-option>
						<el-option label="正常" :value="1"></el-option>
						<el-option label="维修中" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="描述" prop="description">
					<el-input v-model.trim="modifyData.description"></el-input>
				</el-form-item>
				<el-form-item label="所在地区" prop="location">
					<el-input v-model.trim="modifyData.location"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="showDialog = false">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    import {updateDevice, getDeviceList, addDevice, removeDevice, getCompanyList} from '../service/main';

    export default {
        name: 'DeviceList',
	    data() {
            return {
                showLoading: true,
	            data: [],
                columns: [
                    {
                        prop: 'sn',
                        label: '设备SN号'
                    },
                    {
                        prop: 'name',
                        label: '设备名称'
                    },
                    {
                        prop: 'statusValue',
                        label: '状态'
                    },
                    {
                        prop: 'companyName',
                        label: '所属公司'
                    },
                    {
                        prop: 'location',
                        label: '所在地区'
                    },
                    {
                        prop: 'createTime',
                        label: '创建时间'
                    }
                ],
                total: 0,
                page: 1,
                modifyData: {},
                rules: {
                    sn: [
                        {required: true, message: '请输入设备SN号', trigger: 'blur'},
                    ],
                    company: [
                        {required: true, message: '请选择设备所属公司', trigger: 'change'},
                    ],
                    name: [
                        {required: true, message: '请输入设备名称', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '请选择设备状态', trigger: 'change'}
                    ]
                },
                modifyDialogLoading: false,
	            showDialog: false,
	            operationType: 'add',
	            companyList: []
            }
	    },
	    mounted() {
            this.showLoading = true;
            this.initCompany().then(() => {
	            this.query(1)
            });
        },
        methods: {
            initCompany() {
                return getCompanyList({all: 1}).then(res => {
                    const {list = []} = res.data;
                    this.companyList = list;
                    const companyMap = {};
                    this.companyList.forEach(item => companyMap[+item.id] = item.name);
                    this.companyMap = companyMap;
                });
            },
            query(page) {
                this.showLoading = true;
                const params = {page, company: -1}
                getDeviceList(params).then(res => {
                    const {count = 0, list = []} = res.data
                    this.total = count
                    const statusMap = new Map([
                        [0, '故障'],
                        [1, '正常'],
                        [2, '维修中']
                    ])
                    this.data = list.map(item => {
                        const temp = {...item}
                        temp.statusValue = statusMap.get(+item.status)
	                    temp.companyName = this.companyMap[+temp.company]
                        return temp
                    })
                    this.showLoading = false
                }).catch(err => {
                    this.showLoading = false
                    this.handleError(err)
                })
            },
	        add() {
                this.operationType = 'add';
                this.showDialog = true;
                this.modifyData = {};
	        },
            modify(row = {}) {
                this.operationType = 'edit';
                this.modifyData = Object.assign({}, row);
                this.showDialog = true;
            },
            removeDevice(id) {
                this.$confirm('是否删除该设备?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.showLoading = true;
                    removeDevice({id}).then(res => {
                        const {code, msg} = res;
                        if (+code === 0) {
                            this.$message.success('删除成功');
                            this.query();
                        } else {
                            this.$message.error(msg);
                        }
                    }).catch(err => {
                        this.showLoading = false;
                        this.handleError(err)
                    });
                }).catch(() => {
                    console.log('cancel delete user');
                });
            },
            confirm() {
                this.$refs.deviceForm.validate(valid => {
                    if (valid) {
                        const isAddType = this.operationType === 'add';
                        this.modifyDialogLoading = true;
                        const api = isAddType ? addDevice : updateDevice;
                        api(this.modifyData).then(res => {
                            this.modifyDialogLoading = false;
                            const {code, msg} = res;
                            if (+code === 0) {
                                this.$message.success(isAddType ? '添加成功' : '修改成功');
                                this.showDialog = false;
                                this.query(1);
                            } else {
                                this.$message.error(msg);
                            }
                        }).catch(err => {
                            this.modifyDialogLoading = false;
                            this.handleError(err)
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
	.device-list {
		.opt {
			margin-bottom: 16px;
			text-align: left;
		}
        .query {
            text-align: left;

            .el-select {
                margin-right: 16px;
            }

            //.el-button {
            //    margin-left: 16px;
            //}
        }

        .el-table {
            margin-top: 16px;
        }

        .el-pagination {
            text-align: right;
	        margin-top: 16px;
        }

		.el-form {
			.el-select {
				width: 100%;
			}
		}
	}
</style>
