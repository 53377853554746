<template>
	<div v-loading="showLoading" class="warning-list">
        <div class="query">
            <el-select v-model="status" placeholder="请选择" @change="query(1)">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-date-picker
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                @change="query(1)"
            ></el-date-picker>
        </div>
		<el-table :data="data" stripe>
			<el-table-column
				v-for="item in columns"
				:key="item.prop"
				:prop="item.prop"
				:label="item.label"
			></el-table-column>
		</el-table>
        <el-pagination
	        background
            layout="total, pager"
            :page-size="pageSize"
            :current-page="page"
            :total="total"
            @current-change="query($event)"
        ></el-pagination>
	</div>
</template>

<script>
    import {getWarningList} from '../service/main';

    export default {
        name: 'WarningList',
	    data() {
            return {
                showLoading: true,
                columns: [
	                {
	                    prop: 'sn',
		                label: 'sn'
	                },
                    {
                        prop: 'deviceName',
                        label: '设备名称'
                    },
                    {
                        prop: 'name',
                        label: '类型'
                    },
                    {
                        prop: 'value',
                        label: '报警浓度值'
                    },
                    {
                        prop: 'statusValue',
                        label: '状态'
                    },
                    {
                        prop: 'createTime',
                        label: '报警时间'
                    },
                    {
                        prop: 'reasonTypeValue',
                        label: '解除原因类型'
                    },
	                {
                        prop: 'reason',
                        label: '解除原因'
                    },
                    {
                        prop: 'submitUser',
                        label: '提交人'
                    },
                    {
                        prop: 'submitTime',
                        label: '提交时间'
                    },
                    {
                        prop: 'approvalUser',
                        label: '审核人'
                    },
                    {
                        prop: 'approvalTime',
                        label: '审核时间'
                    }
                ],
                options: [
                    {value: -1, label: '全部'},
                    {value: 0, label: '未处理'},
                    {value: 2, label: '处理中'},
                    {value: 1, label: '已处理'}
                ],
                data: [],
                date: this.genInitDate(),
                status: -1,
                total: 0,
                page: 1,
                pageSize: 10
            }
	    },
	    mounted() {
            this.query(1)
        },
        methods: {
            genInitDate() {
                const currentDate = new Date()
                const weekBeforeDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
                return [weekBeforeDate, currentDate]
            },
            query(page) {
                const dateStart = this.date[0].format('yyyy-MM-dd hh:mm:ss')
                const dateEnd = this.date[1].format('yyyy-MM-dd hh:mm:ss')
                const params = {
                    status: this.status,
                    dateStart,
                    dateEnd,
                    rows: this.pageSize,
                    page,
	                company: 5
                }
                getWarningList(params).then(res => {
                    const {count = 0, list = []} = res.data
                    this.total = count
                    this.data = list.map(item => {
                        const temp = {...item}
                        this.transformProp(temp)
                        return temp
                    })
                    this.showLoading = false
                }).catch(err => {
                    this.showLoading = false
                    this.handleError(err)
                })
            },
	        transformProp(item) {
                const statusMap = new Map([
                    [0, '未处理'],
                    [1, '已处理'],
                    [2, '处理中']
                ])
		        const reasonTypeMap = new Map([
		            [1, '误报'],
		            [2, '测试'],
		            [3, '其他']
		        ])
                item.statusValue = statusMap.get(+item.status)
                item.reasonTypeValue = reasonTypeMap.get(+item.reasonType)
            }
        }
    }
</script>

<style lang="scss" scoped>
	.warning-list {
        .query {
            text-align: left;

            .el-select {
                margin-right: 16px;
            }

            //.el-button {
            //    margin-left: 16px;
            //}
        }

        .el-table {
            margin-top: 16px;
        }

        .el-pagination {
            text-align: right;
	        margin-top: 16px;
        }
	}
</style>
